import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";
import ContactForm from "../components/ContactForm";

import Carousel from "../components/menuCarousel";

const IndexPage = () => {
	return (
		<main id="Home">
			<NavbarWeb></NavbarWeb>
			<div className="mobileNavContainer">
				<div className="navLogo">
					<StaticImage
						src="../images/icon2.png"
						placeholder="blurred"
						width={346}
						height={720}
						formats={["auto", "webp", "avif"]}
						alt="Kyoto Garden Japanese Restaurant"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<MobileNav></MobileNav>
			</div>

			<header className="hero-banner">
				<div className="hero-banner-text-container">
					<h1>
						Tailored Solutions for Island Logistics.
					</h1>
					<p>As a prominent procurement, logistics and supply chain agency in South Africa, Chestnut Hill takes pride in being a comprehensive procurement solution provider. With 12 years of dedicated service, we have been offering sophisticated and customized services in key locations worldwide, meticulously tailored to meet your specific needs.</p>
				</div>
				<div className="image-container">
					<StaticImage
						src="../images/test123123123123.png"
						placeholder="tracedSVG"
						width={1920}
						height={600}
						formats={["auto", "webp", "avif"]}
						alt="Koshi sushi chef"
						transformOptions={{ fit: "cover", cropFocus: "north" }}
					/>
				</div>
			</header>
			<section>
				<div className="two-grid first">
					<div className="large-text">
					Partnering with Excellence: Chestnut Hill and Mehuizen Freight
					</div>
					<div className="small-text">
					At Chestnut Hill, we are proud to announce our partnership with Mehuizen Freight, a distinguished leader in freight services. This collaboration marks a significant milestone in our commitment to providing exceptional procurement services, particularly in the niche area of island supply and logistics within South Africa.
					</div>
				</div>
			</section>

			<section>
				<div className="two-grid cozy-grid">
					<div className="left text">
						<h1>Our Specialization</h1>
						<p>
						As a procurement company, Chestnut Hill excels in sourcing and delivering a diverse range of products to various locations, with a keen focus on island destinations. Our expertise lies in navigating the unique challenges that island logistics present, ensuring that every delivery is made with precision and care.
						</p>
					</div>
					<div className="right">
						<div className="image-container image1">
							<StaticImage
								src="../images/pexels-tiger-lily-4483938.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="Interior of a Modern Cape Town Sushi Restaurant"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
				</div>
				<div className="two-grid whisky-grid">
					<div className="left">
						<div className="image-container image1">
							<StaticImage
								src="../images/IMG_6897_new6-1-scaled.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="South African Whisky Pairings with Sushi"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
					<div className="right text">
						<h1>Synergy with Mehuizen Freight</h1>
						<p>
						Mehuizen Freight, with their esteemed history and proven track record in freight services, complements our services perfectly. Their expertise in handling complex shipping requirements and customs procedures, coupled with our procurement skills, ensures a seamless, efficient, and reliable delivery process.
						</p>
					</div>
				</div>

				<div className="two-grid cozy-grid">
					<div className="left text">
						<h1>Tailored Solutions for Island Logistics</h1>
						<p>
						Together, Chestnut Hill and Mehuizen Freight are poised to offer tailored solutions for island logistics, ensuring that even the most remote locations are accessible for our clients. Our combined efforts are focused on minimizing logistical complexities and optimizing supply chains, making us the go-to choice for businesses and communities on islands and across South Africa.
						</p>
					</div>
					<div className="right">
						<div className="image-container image1">
							<StaticImage
								src="../images/istockphoto-1443009162-612x612.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="Interior of a Modern Cape Town Sushi Restaurant"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
				</div>

				<div className="two-grid whisky-grid">
					<div className="left">
						<div className="image-container image1">
							<StaticImage
								src="../images/IMG_6897_new6-1-scaled.jpg"
								placeholder="blurred"
								width={680}
								height={382}
								formats={["auto", "webp", "avif"]}
								alt="South African Whisky Pairings with Sushi"
								transformOptions={{ fit: "cover", cropFocus: "attention" }}
							/>
						</div>
					</div>
					<div className="right text">
						<h1>Commitment to Excellence</h1>
						<p>
						This partnership underscores our mutual commitment to excellence, reliability, and customer satisfaction. Whether it's a small consignment or a large-scale procurement project, our clients can trust us to deliver outstanding results, supported by the expertise and infrastructure of Mehuizen Freight.
						</p>
					</div>
				</div>
			</section>
				{/* <ContactForm/> */}

			{/* <div className="awards-grid">
				<div className="image-container image1">
					<StaticImage
						src="../images/Rossouws.png"
						placeholder="blurred"
						width={125}
						height={125}
						formats={["auto", "webp", "avif"]}
						alt="Award Winning Rossouws"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<div className="image-container image1">
					<StaticImage
						src="../images/Eat-Out-2014-Award.png"
						placeholder="blurred"
						width={125}
						height={125}
						formats={["auto", "webp", "avif"]}
						alt="Eat Out Award High Reviews"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</div> */}

			{/* <div className="carousel-container">
				<Carousel></Carousel>
			</div> */}

			<section class="info-section">
				<div className="left"></div>
				<div className="middle"></div>
				<div className="right"></div>
			</section>
			<Footer></Footer>
		</main>
	);
};

export default IndexPage;

export const Head = () => (
	<>
		<title>Chestnut Hill | Procurement Expertise in Island Logistics</title>
		<meta
			name="description"
			content="Chestnut Hill, in partnership with Mehuizen Freight, specializes in procurement and logistics solutions for island destinations and across South Africa. Discover our expert services and tailored solutions."
		/>
	</>
);
